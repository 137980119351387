@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    letter-spacing: inherit;
  }
  html {
    @apply font-medium;
    @apply text-offBlack;
    @apply text-md;
  }
}

@layer components {
  .linkTextNavigation {
    @apply decoration-[0.1em] underline-offset-[0.1em] hover:underline;
  }

  .portableText {
    ol {
      list-style-type: decimal;
    }
    ol ol {
      list-style-type: lower-alpha;
    }
    ol ol ol {
      list-style-type: lower-roman;
    }

    ul {
      list-style: disc outside;
    }
    ul ul {
      list-style: circle outside;
    }
    ul ul ul {
      list-style: square outside;
    }
  }

  .select {
    align-items: center;
    background-color: theme('colors.lightGray');
    border-radius: theme('borderRadius.full');
    color: theme('colors.offBlack');
    display: flex;
    font-size: theme('fontSize.sm');
    font-weight: theme('fontWeight.bold');
    height: 2.5rem;
    justify-content: center;
    letter-spacing: theme('letterSpacing.normal');
    overflow: hidden;
    padding: theme('padding.4');
    transition: all 200ms ease-out;
  }

  .select:disabled {
    color: theme('colors.darkGray');
  }

  .select:hover:not(:disabled) {
    opacity: 0.9;
  }
}

* {
  font-variant-ligatures: none;
}

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  background: var(--pageBG);
  overflow-y: scroll;
}

body {
  max-width: 1920px;
  margin: 0 auto;
}

header {
  max-width: 1920px;
  margin: 0 auto;
}

.search-panel {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
/* *** */

.blockAddToCart {
  position: relative;
  width: 100%;
  height: auto;
}

.blockAddToCart::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(244, 244, 240, 0.8);
}

.chooseYourSize {
  position: absolute;
  z-index: 5;
  width: 100%;
  text-align: center;
  padding-top: 25px;
}

.embla__slide {
  position: relative;
  flex: 0 0 100%;
}

/* Variables */
:root {
  --black: #000000;
  --white: #ffffff;

  --black-hsl: 0, 0%, 0%;
  --white-hsl: 0, 0%, 100%;

  --pageBG: #f4f4f0;
  --pageText: #000000;

  --pageBG-hsl: 60, 14%, 95%;
  --pageText-hsl: 0, 0%, 0%;

  --colorsHowyLightGrey: #dfe4e5;
  --colorsHowyBeige: #cab8a1;
  --colorsHowyGrey: #bdc2c5;
  --colorsHowyGreen: #77d2bd;
  --colorsHowyBlue: #89adc6;

  --colorStab: var(--colorsHowyGrey);
}

.stabCalloutBgGreen {
  position: relative;

  &:before {
    position: absolute;
    z-index: -1;
    top: -20px;
    left: -50px;
    right: -50px;
    bottom: -30px;
    content: '';
    background: var(--colorsHowyGreen);
  }
  background: var(--colorsHowyGreen);
}

.stabCalloutBgBlue {
  position: relative;

  &:before {
    position: absolute;
    z-index: -1;
    top: -20px;
    left: -50px;
    right: -50px;
    bottom: -30px;
    content: '';
    background: var(--colorsHowyBlue);
  }
  background: var(--colorsHowyBlue);
}

.stabCalloutBgLightGrey {
  position: relative;

  &:before {
    position: absolute;
    z-index: -1;
    top: -20px;
    left: -50px;
    right: -50px;
    bottom: -30px;
    content: '';
    background: var(--colorsHowyLightGrey);
  }
  background: var(--colorsHowyLightGrey);
}

.list-payment li {
  padding-right: 5px;
  padding-bottom: 5px;
}

.isA {
  color: #000;
}

.isNotA {
  text-decoration-color: red;
}

.stabBg {
  background: var(--pageBG);
}

.stabHeroBg {
  background: var(--colorsHowyGreen);
}

.stabFilterExpand {
  height: auto;
}

.stabFilterClosed {
  height: 0px;
  overflow: hidden;
}

.stabFilterToggle {
  margin-bottom: 1rem;
}

.stabBgFooter {
  border: 1px solid var(--colorStab);
  background: var(--pageBG);
}

.stabBorderDefault {
  border-bottom: 1px solid var(--colorStab);
}

.stabBorderColor {
  border: 1px solid var(--colorStab);
}

.select {
  border: 1px solid var(--colorStab);
}

.stabBorderButton {
  border: 1px solid var(--colorStab);
}

.stabLogo {
  width: 160px;
  height: auto;
}

.logoA svg path {
  fill: var(--black);
}

.logoB svg path {
  fill: var(--black);
}

.stabGalleryDetail {
  position: relative;
}

.stabButtonDefault {
  border: 1px solid black;
  color: black;
  background-color: rgba(0, 0, 0, 0);
}

.stabButtonActive {
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 1);
}

.stabButtonClear {
  border: 1px solid black;
  color: var(--colorsHowyGreen);
  background-color: rgba(0, 0, 0, 0);
}

/* *** */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.searchbox input {
  border: 1px solid black;
  width: 100%;
  padding: 15px;
  font-size: 15px;
  margin-bottom: 20px;
}

.searchbox button {
  display: none;
}

/* *** */

.stabMarquee {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

[data-marqy] {
  position: relative;
  overflow: hidden;
}

[data-marqy-inner] {
  display: flex;
}

[data-marqy][data-direction='right'] [data-marqy-inner] {
  justify-content: flex-end;
}

[data-marqy-content] {
  display: flex;
  flex: 1 0 auto;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  will-change: transform;
}

[data-marqy][data-direction='left'] [data-marqy-content] {
  animation-name: marqyL;
}

[data-marqy][data-direction='right'] [data-marqy-content] {
  animation-name: marqyR;
}

[data-marqy][data-pause-on-hover]:hover [data-marqy-content] {
  animation-play-state: paused;
}

[data-marqy-item] {
  flex-grow: 0;
}

@keyframes marqyL {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marqyR {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@media (prefers-reduced-motion) {
  [data-marqy-inner] {
    overflow-x: scroll;
  }

  [data-marqy-content] {
    animation: none !important;
  }
}

.marquee {
  @apply border-b;

  &--item {
    @apply flex;
  }

  &--product {
    @apply -my-1 flex max-w-[450px] flex-col;
    width: calc(80vw - 2rem);

    margin: 5px;
    padding: 5px;

    * {
      @apply whitespace-normal;
    }

    .product-card {
      @apply flex-1;
    }
  }
}

.ais-Hits-item:has(.hidebox) {
  display: none;
}

.hitboxcont {
  display: flex;
  flex-direction: column;
}

.hitboxbreak {
  height: 10px;
}

.hidebox {
  display: none;
}

.hitsbox ul {
  display: flex;
  flex-wrap: wrap;
}

.hitsbox li {
  flex: 0 1 auto;
}

.hitbox {
  width: auto;
  max-width: 200px;
  margin-bottom: 30px;
  padding-right: 10px;
}

/* *** */

.descriptionCollection {
  margin-top: 2rem;
}

.descriptionCollection h1 {
  font-size: 4.625rem !important;
  line-height: 1 !important;
  letter-spacing: -0.03em !important;
  margin-bottom: 0.5rem !important;
}

.descriptionCollection h2 {
  font-size: 2.5rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionCollection h3 {
  font-size: 1.5rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionCollection h4 {
  font-size: 1.25rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionCollection p,
span {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  letter-spacing: 0px !important;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;

  padding-bottom: 10px;
}

.descriptionCollection ul {
  margin-top: 0.5rem !important;
  margin-bottom: 1rem !important;
  letter-spacing: 1rem !important;
  line-height: 1.25rem !important;
  list-style: inside !important;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
}

.descriptionCollection ul li {
  letter-spacing: 0px !important;
}

/* *** DETAIL VIEW */

.descriptionHtml {
  margin-top: 2rem;
}

.descriptionHtml h1 {
  font-size: 2.5rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionHtml h2 {
  font-size: 1.5rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionHtml h3 {
  font-size: 1.5rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionHtml h4 {
  font-size: 1.25rem !important;
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
  margin-top: 0.5rem !important;
}

.descriptionHtml p,
span {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  letter-spacing: 0px !important;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;

  padding-bottom: 10px;
}

.descriptionHtml ul {
  margin-top: 0.5rem !important;
  margin-bottom: 1rem !important;
  letter-spacing: 1rem !important;
  line-height: 1.25rem !important;
  list-style: inside !important;
  font-family: DM Sans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif !important;
}

.descriptionHtml ul li {
  letter-spacing: 0px !important;
}

.sliderExtra img {
  width: 100%;
  height: auto;
  padding: 50px !important;
}

.swiper {
}

@media only screen and (max-width: 768px) {
  .swiper {
  }
}

.swiper-button-prev {
  color: #000 !important;
}

.swiper-button-next {
  color: #000 !important;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}
